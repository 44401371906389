import React from "react";

export const Header = () => {
  return (
    <div className="header">
      <div />
      <div className="download">Download Resume</div>
    </div>
  );
};
