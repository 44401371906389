import React from "react";
import { Header } from "../components/Header";
import { Content } from "./Content";

export const Home = () => {
  return (
    <div className="home">
      <Header />
      <div className="body">
        <Content />
      </div>
    </div>
  );
};
