import React from "react";
import "../styles/content.css";
import Logo from "../images/logo.png";
import Link from "../images/external-link.png";

export const Content = () => {
  return (
    <div className="content">
      <div className="head">
        <img
          src={Logo}
          width={120}
          height={120}
          style={{ marginRight: "20px" }}
          alt="logo"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ fontWeight: 400, fontSize: 22 }}>Aishwarya Rao</div>
          <div style={{ fontSize: 16, color: "#ABA6A5", lineHeight: 1.5 }}>
            Fullstack Developer
          </div>
        </div>
      </div>
      <div style={{ fontWeight: 600, marginTop: 30 }}>About</div>
      <div style={{ marginTop: 10, fontSize: 14, lineHeight: 1.5 }}>
        Hi, I'm Aishwarya. I'm a fullstack developer based in Singapore. I enjoy
        building products from start to finish. Previously, worked on improving
        ads at Gojek Tech and building enterprise products at
        Hasura.
      </div>
      <div style={{ fontWeight: 600, marginTop: 45, marginBottom: 30 }}>
        Work Experience
      </div>
      <div style={{ display: "flex", lineHeight: 1.8, marginBottom: 20 }}>
        <div style={{ minWidth: 100, fontSize: 14 }}> 2021 - 2023</div>
        <div style={{ marginLeft: 10 }}>
          <div style={{ fontWeight: 600 }}>
            Senior Backend Developer - Gojek{" "}
            <a
              href="https://www.gojek.com/en-id/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Link} width={12} style={{ marginLeft: 5 }} alt="link" />
            </a>
          </div>
          <div style={{ fontSize: 14 }}>
            • Worked with the Ads team at Gojek to build a system using Golang
            and Elixir which serves ads for merchants on various user facing
            applications.
            <br />• Worked on improving search ads relevancy which improved the
            CTR from 3.5% to 5.28%.
            <br />• Worked on building a new retriever for search ads which
            improved the ads visibility and the entire search ads revenue has
            grown by 92% resulting in an incremental ad revenue of 92k/month.
          </div>
          <div>Elixir • Golang • PostgreSQL • Elasticsearch</div>
        </div>
      </div>
      <div style={{ display: "flex", lineHeight: 1.8, marginBottom: 20 }}>
        <div style={{ minWidth: 100, fontSize: 14 }}> 2019 - 2021</div>
        <div style={{ marginLeft: 10 }}>
          <div style={{ fontWeight: 600 }}>
            Fullstack Developer - Hasura{" "}
            <a href="https://hasura.io/" target="_blank" rel="noreferrer">
              <img src={Link} width={12} style={{ marginLeft: 5 }} alt="link" />
            </a>
          </div>
          <div style={{ fontSize: 14 }}>
            • Worked on building frontend of Hasura Cloud from scratch using
            ReactJS and Typescript and backend features using Golang
            <br />• Worked on frontend for enterprise version of Hasura Console
            called Hasura Pro
            <br />• Worked on performance enhancement of Hasura opensource
            console and also integrating similar performance enhancements for
            enterprise clients using Hasura console.
          </div>
          <div>Javascript • Typescript • ReactJS • Golang</div>
        </div>
      </div>
      <div style={{ display: "flex", lineHeight: 1.8, marginBottom: 20 }}>
        <div style={{ minWidth: 100, fontSize: 14 }}> 2018 - 2019</div>
        <div style={{ marginLeft: 10 }}>
          <div style={{ fontWeight: 600 }}>
            Frontend Developer - Scripbox{" "}
            <a href="https://scripbox.com/" target="_blank" rel="noreferrer">
              <img src={Link} width={12} style={{ marginLeft: 5 }} alt="link" />
            </a>
          </div>
          <div style={{ fontSize: 14 }}>
            • Worked on building new features for the Scripbox investment web
            application using ReactJS.
            <br />• Worked on building new features for the Scripbox mobile
            application using React Native.
            <br />• Worked on building a design system in collaboration with the
            UX team to standardize the design across the mobile and web
            platforms at Scripbox.
          </div>
          <div>Javascript • ReactJS • React Native • CSS</div>
        </div>
      </div>
      <div style={{ display: "flex", lineHeight: 1.8, marginBottom: 20 }}>
        <div style={{ minWidth: 100, fontSize: 14 }}> 2018 - 2018</div>
        <div style={{ marginLeft: 10 }}>
          <div style={{ fontWeight: 600 }}>
            Frontend Developer Intern - Trinet{" "}
            <a href="https://www.trinet.com/" target="_blank" rel="noreferrer">
              <img src={Link} width={12} style={{ marginLeft: 5 }} alt="link" />
            </a>
          </div>
          <div style={{ fontSize: 14 }}>
            • Worked on migrating native android application to a hybrid React
            Native application.
            <br />• Worked on setting up an automated testing framework for the
            React Native app using detox automation framework.
          </div>
          <div>Javascript • ReactJS • React Native • Automation Testing</div>
        </div>
      </div>
    </div>
  );
};
